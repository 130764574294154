<template>
    
    <div class="pl-10 pr-12 mt-6">
        <loader :loading="loading" />
        <div class="box-title text-tiny">Opportunities creation lifecycle</div>
        <div class="h-24 flex-none">
            <chart-bubble :data="bubbleData" />
        </div>
    </div>

</template>

<script>

import { state } from '@/store';

export default {

    data() {
        return {
            bubbleData: [],
            loading: false
        }
    },
    methods: {
        load() {

            this.loading = true;

            // if(state.rolSelected.role_type_link != 'presidencia'){
            
                this.axios.get('pipeline/probability', {params: this.params}).then(response => {
                    this.bubbleData = [
                        { x: 25, y:  2, z: response.data.data.twenty_five, name: '25%' },
                        { x: 50, y:  2, z: response.data.data.fifty, name: '50%' },
                        { x: 75, y:  2, z: response.data.data.seventy_five, name: '75%' },
                        { x: 100, y: 2, z: response.data.data.hundred, name: '100%' },
                    ]
                })

            // }

            this.loading = false
        }
    },
    computed: {
        params() {
            let params = state.globalParams
            if (this.$route.params.catId) {
                params = {...params, ...{ sectorAmbiciona: this.$route.params.catId }}
            }
            return params
        }
    },
    watch: {
        params() { this.load() }
    },
    mounted() { this.load() }
}

</script>